import React, { useCallback, useEffect, useState } from 'react'

/* API Hooks */
import useBookingServiceApi from '../../hooks/api/useBookingServiceApi.ts'
import ActionButtons from '../shared/ActionButtons/ActionButtons.tsx'
import BasicButton from '../shared/BasicButton/BasicButton.tsx'
import CalendarComponent from '../shared/CalendarComponent/CalendarComponent.tsx'
import CarouselComponent from '../shared/CarouselComponent/CarouselComponent.tsx'
import EventTypeComponent from '../shared/EventTypeComponent/EventTypeComponent.tsx'
import LoadingSpinner from '../shared/LoadingSpinner/LoadingSpinner.tsx'

/* Components */
import ModalPortal from '../shared/ModalPortal/ModalPortal.tsx'
import PopupComponent from '../shared/PopupComponent/PopupComponent.tsx'

/* Icons */
import { ArrowLeftIcon, FaceSmileIcon } from '@heroicons/react/24/outline'
import {
    addMonths,
    differenceInMinutes,
    isWithinInterval,
    lastDayOfMonth,
    parseISO,
    startOfMonth,
} from 'date-fns'

import dynamic from 'next/dynamic.js'

import { useGetTranslations } from '@/i18n/index.tsx'

/* Context */
import { useApplicationContext } from '@/context/ApplicationContext.tsx'

/* Constants */
import { NOTIFICATION_TYPE } from '@/constants/constants.ts'

import { compareDates, formatDate } from '@/utils/calendarUtils.ts'

/* Utils */
import { debounce } from '@/utils/functionUtils.ts'

/* Hooks */
import useQueryDateRange from '@/hooks/useQueryDateRange'
import useWindowSize from '@/hooks/useWindowSize'

import { ExtendedEventsT, ReservationSubmitValuesТ, ReservationT } from '@/types/globalTypes.js'

const DynamicViewEventsInformationComponent = dynamic(
    () => import('../shared/ViewEventsComponent/ViewEventsInformationComponent.tsx'),
    {
        loading: () => (
            <div className="flex justify-center w-full h-full">
                <LoadingSpinner className="m-auto" />
            </div>
        ),
        ssr: false,
    }
)
const DynamicCreateOrEditReservationComponent = dynamic(
    () =>
        import(
            '@/components/shared/CreateOrEditReservationComponent/CreateOrEditReservationComponent.tsx'
        ),
    {
        loading: () => (
            <div className="flex justify-center w-full h-full">
                <LoadingSpinner className="m-auto" />
            </div>
        ),
        ssr: false,
    }
)

type BookingComponentProps = {
    dateToday: Date
    minDate: Date
    maxDate: Date
    events: ExtendedEventsT[]
    days_of_operation: number[]
    selectedDate: Date
    setActiveMonth: React.Dispatch<
        React.SetStateAction<{
            startOfMonth: string
            endOfMonth: string
        }>
    >
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
    allow_reservations: boolean
}

const BookingComponent = ({
    dateToday,
    minDate,
    maxDate,
    events,
    days_of_operation,
    selectedDate,
    setActiveMonth,
    setSelectedDate,
    allow_reservations,
}: BookingComponentProps) => {
    const { createReservationWithoutSelect } = useBookingServiceApi()
    const { BOOKING_PAGE_TEXT, ACTION_BUTTONS_LABELS, TOAST_NOTIFICATIONS_TEXT } =
        useGetTranslations()
    const { setToastNotification, applicationState } = useApplicationContext()
    const { ending_hours, working_hours } = useQueryDateRange(selectedDate)
    const { width } = useWindowSize()

    const { reservation_lead_time, id: venueId } = applicationState.venue

    const [selectedEvents, setSelectedEvents] = useState<ExtendedEventsT[]>([])
    const [openEventsDetailPopup, setOpenEventsDetailPopup] = useState(false)
    const [reserservationSteps, setReserservationSteps] = useState(0)
    const [confirmedReservationPopup, setConfirmedReservationPopup] = useState(false)
    const [loading, setLoading] = useState(false)

    // Function to identify events for a given date
    const getEventsForDate = useCallback(
        (date: Date) => {
            const eventsForDate = events.filter((event) => {
                if (event.is_recurring) {
                    const dayOfWeek = date.getDay()
                    return event.recurring_days.includes(dayOfWeek)
                } else {
                    const eventStartDate = parseISO(event.start_date)
                    const eventEndDate = parseISO(event.end_date)
                    date.setHours(0, 0, 0, 0)
                    eventStartDate.setHours(0, 0, 0, 0)
                    eventEndDate.setHours(0, 0, 0, 0)
                    return isWithinInterval(date, { start: eventStartDate, end: eventEndDate })
                }
            })
            return eventsForDate
        },
        [events]
    )

    useEffect(() => {
        const eventsForDate = getEventsForDate(dateToday)
        if (eventsForDate.length > 0) {
            setSelectedEvents(eventsForDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateToday])

    const tileContent = useCallback(
        ({ date }: { date: Date }) => {
            const eventsForDate = getEventsForDate(date)

            if (eventsForDate.length > 0) {
                // Aggregate all images from all events on this day into a single array
                const allImages = eventsForDate.flatMap((event) =>
                    event.image_url ? [{ url: event.image_url, eventName: event.name }] : []
                )
                const dayOfMonth = date.getDate()
                const scale = (dayOfMonth - 1) * (2000 / 30) // This scales the day of the month to a range of 0 to 2000
                const delay = 4000 + scale // This shifts the range to 4000 to 6000

                return (
                    <div className="absolute p-0 m-auto text-xs flex !leading-[18px] w-full h-full">
                        <CarouselComponent
                            options={{ loop: true, align: 'center', axis: 'x', delay: delay }}
                            images={allImages}
                        />
                        <EventTypeComponent
                            containerClassName={`absolute right-0.5 z-20 flex ${eventsForDate.length > 1 ? '' : 'max-sm:w-6/12 md:!w-3/12'} max-sm:w-5/12 md:w-4/12 gap-0.5 bg-opacity-70 top-2 max-sm:top-0.5 badge p-0  border-opacity-30 border-slate-500 drop-shadow-md border-0`}
                            eventCount={eventsForDate.length}
                        />

                        {allImages.length > 0 ? (
                            <div className="absolute w-full h-full bg-black bg-opacity-20" />
                        ) : null}
                    </div>
                )
            }
        },
        [getEventsForDate]
    )

    const tileClassName = useCallback(
        ({ date }: { date: Date }) => {
            if (!minDate || !maxDate || !selectedDate) {
                return undefined
            }
            const classNames = []
            const eventsForDate = getEventsForDate(date)

            classNames.push(
                'p-0 relative transition-all duration-150 group flex text-start align-top max-sm:[&>abbr]:top-auto max-sm:[&>abbr]:bottom-0.5 !rounded-none border-opacity-20 border border-opacity-0 border-slate-500 [&>abbr]:mt-0 [&>abbr]:absolute [&>abbr]:top-2 [&>abbr]:left-2 [&>abbr]:z-20 [&>abbr]:!bg-opacity-0 !rounded max-sm:text-xs font-semibold'
            )

            if (compareDates(parseISO(formatDate(selectedDate)), date)) {
                classNames.push('border-success !border-opacity-100 ')
            }

            if (eventsForDate.length > 0) {
                const allImages = eventsForDate.flatMap((event) =>
                    event.image_url ? [event.image_url] : []
                )
                classNames.push(
                    `[&>abbr]:max-sm:text-xs [&>abbr]:!bg-opacity-70 [&>abbr]:border-0 [&>abbr]:!left-0.5 ${allImages.length > 0 ? '[&>abbr]:badge [&>abbr]:badge-neutral max-sm:[&>abbr]:w-5' : ''}`
                )
            }
            if (compareDates(parseISO(formatDate(dateToday)), date)) {
                classNames.push(
                    `[&>abbr]:max-sm:text-xs [&>abbr]:!bg-opacity-70 [&>abbr]:border [&>abbr]:border-success [&>abbr]:!left-0.5 [&>abbr]:badge [&>abbr]:badge-neutral max-sm:[&>abbr]:w-5 [&>abbr]:text-success`
                )
            }

            return classNames.join(' ')
        },
        [dateToday, getEventsForDate, maxDate, minDate, selectedDate]
    )

    const disableDate = useCallback(
        ({ date }: { date: Date }) => {
            const currentDayOfWeek = dateToday.getDay()

            const { starting_hours } = working_hours[currentDayOfWeek]
                ? working_hours[currentDayOfWeek]
                : { starting_hours: '00:00' }

            // Parse the starting hours and minutes
            const [startingHours, startingMinutes] = starting_hours.split(':').map(Number)

            // Create a new Date object for the current time
            const currentTime = new Date()

            // Create a new Date object for the venue's starting time, using the current date
            const startingTime = new Date()
            startingTime.setHours(startingHours, startingMinutes, 0, 0)

            // Convert reservation_lead_time to minutes
            // Assuming reservation_lead_time is in the format 'HH:MM:SS'
            const [hours, minutes] = reservation_lead_time.split(':').map(Number)
            const leadTimeInMinutes = hours * 60 + minutes

            const dateStr = formatDate(date)
            const dateTodayStr = formatDate(dateToday)

            // Disable if the starting hours are within the lead time
            if (
                dateStr === dateTodayStr &&
                differenceInMinutes(startingTime, currentTime) < leadTimeInMinutes
            ) {
                return true
            } else {
                const dayOfWeek = date.getDay()
                return !days_of_operation.includes(dayOfWeek)
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dateToday, days_of_operation, working_hours, selectedDate, reservation_lead_time]
    )

    const handleSelectDate = (date: Date) => {
        setSelectedDate(date)
        const eventsForDate = getEventsForDate(date)

        if (eventsForDate.length > 0) {
            setSelectedEvents(eventsForDate)
            setOpenEventsDetailPopup(width <= 1280)
        } else {
            setSelectedEvents([])
            setOpenEventsDetailPopup(width <= 1280)
        }
    }

    const handleCreateReservation = async (values: ReservationSubmitValuesТ) => {
        const reservationData = {
            id: values.id,
            name_of_the_person: values.fullName,
            email: values.email,
            phone_number: values.phone,
            date_of_reservation: values.date_of_reservation,
            start_time: values.start_time,
            people: values.people,
            status: values.status,
            extra_information: values.extra_information,
            end_time: ending_hours,
            is_temporary: true,
            agreed_to_terms: values.agreed_to_terms,
            venue_id: venueId,
        } as ReservationT

        const [error, data] = await createReservationWithoutSelect(reservationData)

        if (error) {
            setToastNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
            })
            setLoading(false)
        } else {
            setConfirmedReservationPopup(true)
            setToastNotification({
                type: NOTIFICATION_TYPE.SUCCESS,
                message: TOAST_NOTIFICATIONS_TEXT.GENERIC_SUCCESS_MESSAGE,
            })
            setLoading(false)
        }
    }

    const reservationCancelPopupButtons = [
        {
            label: BOOKING_PAGE_TEXT.CREATE_RESERVATION_BUTTON,
            className: 'shadow-md btn dark:btn-success no-animation',
            onClick: () => setReserservationSteps(1),
        },
    ]
    const confirmedReservationPopupButtonsArr = [
        {
            label: ACTION_BUTTONS_LABELS.CONFIRM,
            className: 'shadow-md btn dark:btn-success no-animation',
            onClick: () => {
                setReserservationSteps(0)
                setOpenEventsDetailPopup(false)
                setConfirmedReservationPopup(false)
            },
        },
    ]

    return (
        <div className="text-center">
            <h2 className="my-5 text-3xl font-semibold">
                {allow_reservations ? BOOKING_PAGE_TEXT.BOOK_NOW : BOOKING_PAGE_TEXT.EVENTS}
            </h2>
            <div className="flex justify-center gap-1">
                <CalendarComponent
                    minDate={minDate}
                    maxDate={maxDate}
                    setSelectedDate={handleSelectDate}
                    selectedDate={selectedDate}
                    titleContent={tileContent}
                    tileClassName={tileClassName}
                    disableDate={disableDate}
                    onActiveStartDateChange={debounce((e) => {
                        const nextMonthDate = addMonths(e.activeStartDate, 1)
                        // Getting the last day of the next month
                        const lastDayNextMonth = lastDayOfMonth(nextMonthDate)

                        setActiveMonth({
                            startOfMonth: formatDate(startOfMonth(e.activeStartDate)),
                            endOfMonth: formatDate(lastDayNextMonth),
                        })
                    }, 100)}
                    containerClassName="justify-center max-sm:mx-auto xl:w-6/12 2xl:w-5/12 transition-all transform w-full px-2 animate-fade animate-once animate-duration-250"
                    className="p-1 shadow bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400"
                />

                {width >= 1280 ? (
                    <div className="relative flex flex-col flex-shrink overflow-x-hidden overflow-y-scroll transition-all border rounded-lg shadow max-sm:shadow-none max-sm:rounded-none min-h-max no-scrollbar max-xl:w-4/12 2xl:w-3/12 bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400 max-sm:pb-10 max-sm:dark:bg-base-100 max-sm:border-0 max-xl:pb-14 animate-fade animate-once animate-duration-250">
                        {selectedEvents.length > 0 &&
                        reserservationSteps === 1 &&
                        allow_reservations ? (
                            <BasicButton
                                onClick={() => setReserservationSteps(0)}
                                className="absolute top-4 left-4 p-0 rounded-full btn btn-sm btn-circle animate-fade animate-delay-100 z-[99999]"
                            >
                                <ArrowLeftIcon className="w-6 h-6 " />
                            </BasicButton>
                        ) : null}
                        {selectedEvents.length > 0 && reserservationSteps === 0 ? (
                            <DynamicViewEventsInformationComponent
                                selectEventButtonsArr={
                                    allow_reservations ? reservationCancelPopupButtons : []
                                }
                                setSelectedEvent={() => {}}
                                selectedEvents={selectedEvents}
                                selectedDate={selectedDate}
                                className="nul"
                            />
                        ) : reserservationSteps === 1 ||
                          (selectedEvents.length === 0 && allow_reservations) ? (
                            <div className="h-full animate-fade">
                                <DynamicCreateOrEditReservationComponent
                                    loading={loading}
                                    date={formatDate(selectedDate)}
                                    handleSubmit={handleCreateReservation}
                                />
                            </div>
                        ) : (
                            <DynamicViewEventsInformationComponent
                                selectEventButtonsArr={
                                    allow_reservations ? reservationCancelPopupButtons : []
                                }
                                setSelectedEvent={() => {}}
                                selectedEvents={selectedEvents}
                                selectedDate={selectedDate}
                                className="nul"
                            />
                        )}
                    </div>
                ) : null}
            </div>

            <ModalPortal>
                {openEventsDetailPopup ? (
                    <PopupComponent
                        open={openEventsDetailPopup}
                        setOpen={(open) => {
                            setOpenEventsDetailPopup(open)
                            setReserservationSteps(0)
                        }}
                        className="relative flex flex-col justify-center z-40 min-h-[120px] w-full max-h-full max-w-fit bg-base-100 dark:bg-base-100 animate-fade animate-duration-300 animate-once animate-ease-out  rounded-lg max-sm:rounded-none max-sm:h-full overflow-hidden"
                        buttonClassName="absolute z-50 cursor-default btn btn-sm btn-circle top-4 right-4 max-sm:right-3 max-sm:top-3"
                    >
                        {selectedEvents.length > 0 && reserservationSteps === 0 ? (
                            <DynamicViewEventsInformationComponent
                                selectEventButtonsArr={
                                    allow_reservations ? reservationCancelPopupButtons : []
                                }
                                setSelectedEvent={() => {}}
                                selectedEvents={selectedEvents}
                                selectedDate={selectedDate}
                                className="overflow-x-hidden overflow-y-scroll transition-all border rounded-lg shadow max-sm:shadow-none max-sm:rounded-none min-h-max no-scrollbar xl:w-3/12 bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400 max-sm:dark:bg-base-100 max-sm:border-0 animate-fade animate-once animate-duration-250 max-sm:h-full"
                            />
                        ) : reserservationSteps === 1 ||
                          (selectedEvents.length === 0 && allow_reservations) ? (
                            <div className="h-full p-4 overflow-auto md:rounded-lg animate-fade bg-base-100 dark:bg-slate-50 dark:bg-opacity-5 border-opacity-10 border-slate-400">
                                {selectedEvents.length > 0 ? (
                                    <BasicButton
                                        onClick={() => setReserservationSteps(0)}
                                        className="absolute p-0 md:rounded-full btn btn-sm btn-circle max-sm:top-3 max-sm:left-3 md:top-4 left-4 z-[99999] animate-fade animate-delay-200"
                                    >
                                        <ArrowLeftIcon className="w-6 h-6" />
                                    </BasicButton>
                                ) : null}
                                <DynamicCreateOrEditReservationComponent
                                    date={formatDate(selectedDate)}
                                    handleSubmit={handleCreateReservation}
                                    loading={loading}
                                />
                            </div>
                        ) : (
                            <DynamicViewEventsInformationComponent
                                selectEventButtonsArr={
                                    allow_reservations ? reservationCancelPopupButtons : []
                                }
                                setSelectedEvent={() => {}}
                                selectedEvents={selectedEvents}
                                selectedDate={selectedDate}
                                className="nul"
                            />
                        )}
                    </PopupComponent>
                ) : null}

                <PopupComponent
                    open={confirmedReservationPopup}
                    setOpen={(open) => {
                        setConfirmedReservationPopup(open)
                        setReserservationSteps(0)
                    }}
                    className="relative flex flex-col justify-center z-40 min-h-[120px] w-full max-h-full  max-w-lg bg-base-100 dark:bg-base-100 animate-fade-up animate-duration-300 animate-once animate-ease-out  rounded-lg max-sm:rounded-none max-sm:h-full"
                    buttonClassName="absolute z-50 cursor-default btn btn-sm btn-circle md:top-4 right-4 max-sm:right-3 max-sm:top-3"
                >
                    <div className="flex flex-col justify-center h-full gap-1 p-3 m-auto md:p-5">
                        <FaceSmileIcon className="w-20 h-20 mx-auto mb-2" />
                        <p className="text-lg font-semibold text-center">
                            {BOOKING_PAGE_TEXT.RESERVATION_EMAIL_INSTRUCTION}
                        </p>
                        <span className="mx-auto text-base text-center text-warning">
                            {BOOKING_PAGE_TEXT.RESERVATION_EMAIL_INSTRUCTION_NOTE}
                        </span>
                        <ActionButtons
                            containerClassName="mx-auto my-4"
                            buttons={confirmedReservationPopupButtonsArr}
                        />
                    </div>
                </PopupComponent>
            </ModalPortal>
        </div>
    )
}

export default BookingComponent
